<template>
  <div class="container">
    <h2 v-if="aid == ''" class="title">添 加 活 动</h2>
    <h2 v-else class="title">修 改 活 动</h2>
    <el-form
      ref="form"
      :model="formcon"
      class="dialogfrom"
      label-width="150px"
      label-position="right"
    >
      <div class="flex">
        <el-form-item :required="true" label="活动名称">
          <el-input v-model="formcon.name" placeholder="活动名称"></el-input>
        </el-form-item>
        <el-form-item :required="true" label="活动类型" >
          <el-select v-model="formcon.type" placeholder="请选择" @change="changeType">
            <el-option
              v-for="item in types"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="活动标题">
          <el-input v-model="formcon.title" placeholder="活动标题"></el-input>
        </el-form-item>
        <el-form-item label="活动城市">
          <el-cascader
            v-model="cityList"
            :options="cityoptions"
            :props="cityProps"
            :placeholder="city"
          ></el-cascader>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item
          :required="true"
          label="活动开始时间"
          style="margin-bottom: 30px"
        >
          <el-date-picker
            v-model="formcon.start_time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item :required="true" label="活动结束时间">
          <el-date-picker
            v-model="formcon.end_time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
      </div>
      <el-form-item label="活动地址">
        <el-input v-model="formcon.address"></el-input>
      </el-form-item>
      <el-form-item label="券包" v-if="formcon.type == 'couponPackage'">
        <el-select v-model="formcon.goods_id" clearable>
          <el-option
            v-for="(option, index) in cpList"
            :key="index"
            :value="option.id"
            :label="option.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="页面路径">
        <el-input v-model="formcon.url"></el-input>
      </el-form-item>
      <div class="flex">
        <el-form-item label="活动标签">
          <el-input
            v-model="formcon.tags"
            placeholder="显示在列表上，如游泳节"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否添加到活动列表">
          <el-switch
            v-model="formcon.source"
            active-text="是"
            inactive-text="否"
            active-value="activityList"
            inactive-value=""
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="背景颜色">
          <el-color-picker v-model="formcon.bg_color"></el-color-picker>
        </el-form-item>
      </div>

      <div class="flex">
        <el-form-item label="封面">
          <avatar-uploader
            @getImgUrl="(v) => (formcon.logo = v)"
            :url="temp.logo"
          >
          </avatar-uploader>
        </el-form-item>
        <el-form-item label="图片简介">
          <images-uploader
            @getImgUrl="(v) => (formcon.image_description = v)"
            :urls="temp.image_description"
          >
          </images-uploader>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="福利图片">
          <images-uploader
            @getImgUrl="(v) => (formcon.gift_image = v)"
            :urls="temp.gift_image"
          >
          </images-uploader>
        </el-form-item>
        <el-form-item label="背景图片">
          <avatar-uploader
            @getImgUrl="(v) => (formcon.background_image = v)"
            :url="temp.background_image"
          >
          </avatar-uploader>
        </el-form-item>
      </div>

      <el-form-item label="活动说明">
        <div v-if="descr.length > 0">
          <div class="item" v-for="(v, i) in descr" :key="i">
            <div class="i-t">
              {{ v.t }}
              <el-button type="danger" @click="delDesc(i)" size="small"
                >删除</el-button
              >
              <el-button type="success" @click="editDesc(i)" size="small"
                >编辑</el-button
              >
            </div>
            <div class="i-d">
              {{ v.d }}
            </div>
          </div>
        </div>
        <div v-else>
          填写下面信息后点击添加
        </div>
      </el-form-item>
      <div>
        <el-form-item label="标题">
          <el-input v-model="descr_item.t" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="说明">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="descr_item.d"
            :autosize="{ minRows: 2, maxRows: 4 }"
          >
          </el-input>
          
          <el-button type="primary" @click="showAddDesc" size="small"
            >{{d_i != '' ? '修改说明':'添加说明'}}</el-button
          >
        </el-form-item>
      </div>
    </el-form>

    <div class="lab">活动规则</div>
    <wangEditor
      v-model="formcon.activity_description"
      @change="wangEditorChange"
      height="300px"
      class="wang"
    ></wangEditor>
    <div class="btns">
      <el-button @click="back">返 回</el-button>
      <el-button
        type="primary"
        class="butt"
        @click="changetrue"
        v-if="aid == ''"
        >添 加 活 动</el-button
      >
      <el-button type="primary" class="butt" @click="upadteActivity" v-else
        >修 改 活 动</el-button
      >
    </div>
  </div>
</template>
<script>
import { ObjectChangedValue } from "@/js/utils";
import wangEditor from "@/components/wangEditor";
export default {
  components: { wangEditor },
  data() {
    return {
      types: [
        {
          value: "course",
          label: "课程",
        },
        {
          value: "couponPackage",
          label: "券包",
        },
        {
          value: "couponPackageGet",
          label: "发券包",
        },
      ],
      QiNiYunL: {},
      formcon: {},
      temp: {},
      cityoptions: [],
      region: "",
      ms: "false",
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      imageUrl3: "",
      background_image: "",
      isClear: true,
      city: "",
      mszt: "1",
      source: false,
      cpList: [],
      aid: "",

      addDesc: false,
      descr: [],
      descr_item: {},
      d_i:''
    };
  },
  created() {
    let citys = JSON.parse(localStorage.getItem("addrList"));
    citys.forEach((item) => {
      if (item.childrens) {
        item.childrens.forEach((c) => {
          delete c.childrens;
        });
      }
    });
    this.cityoptions = citys;
    if (this.$route.query.aid) {
      this.aid = this.$route.query.aid;
      this.getActivityInfo();
    }
  },
  mounted() {
    this.wangEditorDetail = "请输入活动内容"; //设置富文本框默认显示内容
  },

  methods: {
    editDesc(i){
        this.d_i = i
        this.descr_item = this.descr[i]
    },
    changeType(v){
        console.log(v);
        if (v == "couponPackage") {
          this.getCouponPackageList();
        }
    },
    delDesc(i) {
      this.descr.splice(i, 1);
    },
    showAddDesc() {
      if(this.d_i == ''){
          this.descr.push(this.descr_item);
      }
      
      this.descr_item = {};
      this.d_i = ''
    },
    //获取列表
    getCouponPackageList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/couponPackage/queryListPage",
        params: {
          currentPage: page,
          pageSize: 20,
          status: 1,
        },
      }).then((res) => {
        this.cpList = res.data.data;
      });
    },

    editInfoHandle(info) {
      //  return info.replace(/(<font color=)/g,"<span style=color:").replace(/(font>)/g,'span>')
      return info;
    },
    back() {
      this.$router.back();
    },
    wangEditorChange(val) {
      this.formcon.activity_description = val;
    },
    getActivityInfo() {
      let url = "/user/businessActivityType/findById?id=" + this.aid;
      this.$axios.get(url).then((res) => {
        this.formcon = res.data.data;
      
        if (this.formcon.type == "couponPackage") {
          this.getCouponPackageList();
        }
        this.cityList = [];
        this.cityList.push(this.formcon.province);
        this.cityList.push(this.formcon.city);
        this.temp = Object.assign({}, res.data.data);
          this.descr =  this.formcon.descr ? JSON.parse( this.formcon.descr) : []
      });
    },

    //添加活动
    changetrue() {
      if (
        !this.formcon.name ||
        !this.formcon.end_time ||
        !this.formcon.start_time
      ) {
        this.$message({ message: "请填写信息" });
        return;
      }
      let data = {
        ...this.formcon,
      };
      if (this.cityList.length == 2) {
        data.province = this.cityList[0];
        data.city = this.cityList[1];
      }
      
      if(this.descr.length > 0){
        data.descr = JSON.stringify(this.descr)
      }

      this.$axios
        .post("/user/businessActivityType/insert", data)
        .then((res) => {
          this.$message({ type: "success", message: res.data.message });
          this.$router.back();
        });
    },
    //修改活动详情
    upadteActivity() {
      let url = "/user/businessActivityType/update";
      if (this.cityList.length == 2) {
        this.formcon.province = this.cityList[0];
        this.formcon.city = this.cityList[1];
      }
       this.formcon.descr = JSON.stringify(this.descr)
      let data = ObjectChangedValue(this.temp, this.formcon);
     

      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }
      data.id = this.aid;
      this.$axios.post(url, data).then((res) => {
        this.$message({ type: "info", message: res.data.message });
        this.$router.back();
      });
    },
  },
};
</script>

<style scoped>
.item {
  background-color: #eee;
  padding: 10px;
  font-size: 13px;
}
.i-t {
  font-size: 15px;
}
.i-d {
  color: #606266;
}
.btns {
  padding: 10px 0 20px 30px;
  text-align: end;
}
.lab {
  font-size: 14px;
  line-height: 2;
  color: #606266;
}
.title {
  padding: 4px 30px 0px 30px;
}
.row {
  width: 100%;
}
</style>